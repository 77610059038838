import * as React from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import Seo from '../components/SEO'

const NotFoundPage = () => {
  return (
    <Layout>
      <main className="relative flex flex-col items-center justify-center">
        <Seo title="Juan Duran | Page not found" desc="Page not found"/>
        <h1 className="mb-12 md:mb-8 text-3xl md:text-5xl text-center">Page not found</h1>
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              Try creating a page in <code>src/pages/</code>
              <br />
            </>
          ) : null}
          <Link to="/" className="underline text-2xl">Go home</Link>
      </main>
    </Layout>
  )
}

export default NotFoundPage
